
import _ from 'lodash';

export default {
  name: 'ObjectivesList',
  props: {
    appConcepts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasAppConcepts() {
      return Array.isArray(this.appConcepts) && this.appConcepts.length > 0;
    },
    objectives() {
      const items = [...this.$store.getters['objectives/items']];
      const latestIndex = this.$store.getters['objectives/itemActiveIndex'];
      let list = [];

      if (typeof latestIndex !== 'number' || latestIndex < 0) {
        list = items.filter(i => i.isUnlocked && i.isSuccessful);
      } else {
        list = items.slice(0, latestIndex + 1);
      }

      if (this.hasAppConcepts) {
        list = list.filter(i => _.intersection(i.concepts, this.appConcepts).length > 0);
      }

      list.reverse();
      return list;
    },
    objectiveActiveName() {
      const item = this.$store.getters['objectives/itemActive'];
      return item ? item.name : undefined;
    },
  },
};
